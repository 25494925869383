import React, { useState } from "react";
import {
  DraggableCore as Draggable,
  DraggableEvent,
  DraggableData,
} from "react-draggable";
import { ReactComponent as Square } from "./svg/square.svg";
import { ReactComponent as SquareSmall } from "./svg/square_small.svg";
import { ReactComponent as Circle } from "./svg/circle.svg";
import { ReactComponent as CircleSmall } from "./svg/circle_small.svg";
import { Colors } from "./components/Colors";

import "./App.css";
import "normalize.css";

type Position = {
  x: number;
  y: number;
};

interface FigureProps {
  figure: any;
  initPos: Position;
}

const Figure = (props: FigureProps) => {
  const [currentPosition, setCurrentPosition] = useState<Position>(
    props.initPos
  );

  const onDrag = (e: DraggableEvent, data: DraggableData) => {
    setCurrentPosition({
      x: currentPosition.x + data.deltaX,
      y: currentPosition.y + data.deltaY,
    });
  };

  return (
    <Draggable onDrag={onDrag}>
      <div
        className="figure"
        style={{
          rotate: "-8deg",
          left: currentPosition.x,
          top: currentPosition.y,
        }}
      >
        <props.figure />
      </div>
    </Draggable>
  );
};

interface Props {
  colors: string[];
}

const Container: React.FC<Props> = (props: Props) => {
  const [aColor, bColor, cColor] = props.colors;
  return (
    <div
      className="container"
      style={{
        background: `repeating-linear-gradient(
				  to right,
				  ${aColor},
				  ${aColor} 10px,
				  ${bColor} 10px,
				  ${bColor} 20px,
				  ${cColor} 20px,
				  ${cColor} 30px)`,
      }}
    >
      <div className="background" />
      <Figure figure={SquareSmall} initPos={{ x: -100, y: 0 }} />
      <Figure figure={CircleSmall} initPos={{ x: 300, y: 0 }} />
    </div>
  );
};

function App() {
  const [aColor, setAColor] = useState("#ff0000");
  const [bColor, setBColor] = useState("#00ff00");
  const [cColor, setCColor] = useState("#0000ff");

  return (
    <div>
      <Container colors={[aColor, bColor, cColor]} />
      <Colors
        colors={[aColor, bColor, cColor]}
        colorSetters={[setAColor, setBColor, setCColor]}
      />
    </div>
  );
}

export default App;
