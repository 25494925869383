import { CompactPicker as Picker } from "react-color";
import Popover from "@mui/material/Popover";
import { useState } from "react";

interface ColorProps {
  color: string;
  setColor: any;
}

export const Color = (props: ColorProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (color: any) => {
    props.setColor(color.hex);
  };

  const open = Boolean(anchorEl);
  const id = open ? "color-popover" : undefined;

  return (
    <>
      <button
        className="color-box"
        style={{ background: props.color }}
        onClick={handleClick}
      ></button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Picker color={props.color} onChange={handleColorChange} />
      </Popover>
    </>
  );
};

interface ColorsProps {
  colors: string[];
  colorSetters: any;
}

export const Colors = (props: ColorsProps) => {
  const [aColor, bColor, cColor] = props.colors;
  const [setAColor, setBColor, setCColor] = props.colorSetters;

  return (
    <div className="colors-container">
      <Color color={aColor} setColor={setAColor} />
      <Color color={bColor} setColor={setBColor} />
      <Color color={cColor} setColor={setCColor} />
    </div>
  );
};
